import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "../../../constants/constants";
import { UsersService } from "../../account-management/users/users.service";
import { TitleService } from "../../../services/title.service";
import { SharedService } from "src/app/services/shared.service";
import { UserPermissions } from "src/app/models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";

import { AnnouncementsConfigurations } from "@zixi/models";
import { AnnouncementsConfigurationsService } from "../announcements-configurations.service";

@Component({
    selector: "app-announcement-configurations",
    templateUrl: "./announcement-configurations.component.html"
})
export class AnnouncementConfigurationsComponent implements OnInit, OnDestroy {
    announcementConfigurations: AnnouncementsConfigurations;
    announcementConfigurationsId: number;
    userPermissions: UserPermissions;
    private AnnouncementsConfigurationsSubscription: Subscription;

    isLoading: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private announcementsConfigurationsServices: AnnouncementsConfigurationsService,
        private modalService: ModalService,
        private userService: UsersService,
        private sharedService: SharedService,
        private titleService: TitleService,
        private mixpanelService: MixpanelService
    ) {
        this.route.paramMap.subscribe(params => {
            this.announcementConfigurationsId = parseInt(params.get("id"), 10);
            if (this.announcementConfigurationsId)
                this.announcementConfigurations =
                    this.announcementsConfigurationsServices.getCachedAnnouncementConfigurations(
                        this.announcementConfigurationsId
                    );

            if (!this.announcementConfigurations) return this.cancel();

            this.titleService.setTitle("ANNOUNCEMENT_CONFIGURATIONS", "", String(this.announcementConfigurations.id));

            if (!this.announcementConfigurations.hasFullDetails) this.isLoading = true;
            this.announcementsConfigurationsServices.refreshAnnouncementConfigurations(
                this.announcementConfigurationsId,
                true
            );
        });
    }

    ngOnInit() {
        this.AnnouncementsConfigurationsSubscription =
            this.announcementsConfigurationsServices.announcementsConfigurations.subscribe(
                announcementsConfigurations => {
                    this.announcementConfigurations = announcementsConfigurations.find(
                        (a: AnnouncementsConfigurations) => a.id === this.announcementConfigurationsId
                    );
                }
            );

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });
    }

    ngOnDestroy() {
        if (this.AnnouncementsConfigurationsSubscription) this.AnnouncementsConfigurationsSubscription.unsubscribe();
    }

    gotoAnnouncementsConfigurations() {
        this.router.navigate([Constants.urls.announcements_configurations]);
    }

    cancel() {
        this.gotoAnnouncementsConfigurations();
    }

    canEdit(announcementConfigurations: AnnouncementsConfigurations) {
        return this.sharedService.canEditZixiObject(announcementConfigurations, null, this.userPermissions);
    }

    async deleteAnnouncementConfigurations() {
        await this.modalService.confirm("DELETE", "ANNOUNCEMENT_CONFIGURATIONS", async () => {
            const id = this.announcementConfigurations.id;
            const result = await this.announcementsConfigurationsServices.deleteAnnouncementConfigurations(
                this.announcementConfigurations
            );
            if (result) {
                this.mixpanelService.sendEvent("delete announcement configurations", { id });
                this.gotoAnnouncementsConfigurations();
            } else {
                return false;
            }
        });
    }

    editAnnouncementConfigurations(id: number): void {
        this.router.navigate([Constants.urls.announcements_configurations, id, "edit"]);
    }
}
